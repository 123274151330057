import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import CollectionGuidelines from "../../static/media/providers/specimen-collection-guidelines.pdf";
import HomeVisits from "../../static/media/providers/home-visits.pdf";
import { Link } from "gatsby";

// eslint-disable-next-line
export const ProvidersPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  testingguides,
  antibioticprofiles,
  forms,
  referenceranges,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            <hr />
            <Link className="has-text-primary" to="/new-requestor">
              <p>
                <strong>
                  New provider? Please follow this link and fill in our secure
                  form to ensure we can deliver results to the right place.
                </strong>
              </p>
            </Link>
            <hr />
            <Link className="has-text-primary" to="/appointments">
              <p>
                <strong>Will my patient need an appointment?</strong>
              </p>
            </Link>
            <hr />
            <Link className="has-text-primary" to="/test-add">
              <p>
                <strong>
                  I'd like to add a test to a request or provide clinical
                  details.
                </strong>
              </p>
            </Link>
            <hr />
            <Link className="has-text-primary" to="/news">
              <p>
                <strong>Where can I find the latest clinical updates?</strong>
              </p>
            </Link>
            <hr />
            {testingguides && testingguides.length ? (
              <div style={{ marginTop: `0` }}>
                <a id="testing-guides" className="anchor">
                  <h4 className="has-text-centered">Testing Guides</h4>
                </a>
                <br />
                <div className="columns is-multiline is-desktop">
                  {testingguides.map((testingguide) => (
                    <div
                      key={testingguide.title + `testingguide`}
                      className="has-text-centered column is-6-desktop"
                    >
                      <div className="simple-border">
                        <h4>{testingguide.title}</h4>
                        <p>{testingguide.description}</p>
                        <a
                          href={testingguide.document.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="has-text-primary"
                        >
                          <strong>View Testing Guide</strong>
                        </a>
                      </div>
                    </div>
                  ))}
                  <div
                    key="choosewisely"
                    className="has-text-centered column is-6-desktop"
                  >
                    <div className="simple-border">
                      <h4>Choosing Wisely</h4>
                      <p>
                        Advisory from The New Zealand Microbiology Network about
                        appropriate testing.
                      </p>
                      <a
                        href="https://choosingwisely.org/professional-resource/nzmn/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="has-text-primary"
                      >
                        <strong>See Advisory</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <hr />
            {antibioticprofiles && antibioticprofiles.length ? (
              <div style={{ marginTop: `0` }}>
                <a id="antibiotic-profiles" className="anchor">
                  <h4 className="has-text-centered">Antibiotic Profiles</h4>
                </a>
                <br />
                <div className="columns is-multiline is-desktop">
                  {antibioticprofiles.map((antibioticprofile) => (
                    <div
                      key={antibioticprofile.title + `antibioticprofile`}
                      className="has-text-centered column is-6-desktop"
                    >
                      <div className="simple-border">
                        <h4>{antibioticprofile.title}</h4>
                        <p>{antibioticprofile.description}</p>
                        <a
                          href={antibioticprofile.document.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="has-text-primary"
                        >
                          <strong>View Antibiotic Profile</strong>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <hr />
            {forms && forms.length ? (
              <div style={{ marginTop: `0` }}>
                <a id="forms" className="anchor">
                  <h4 className="has-text-centered">Forms</h4>
                </a>
                <br />
                <div className="columns is-multiline is-desktop">
                  <div className="has-text-centered column is-6-desktop">
                    <div className="simple-border">
                      <a
                        href="https://www.pathlab.co.nz/skin-prick"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="has-text-primary"
                      >
                        <strong>
                          Skin Prick Testing - Online Request Form
                        </strong>
                      </a>
                    </div>
                  </div>
                  {forms.map((form) => (
                    <div
                      key={form.title + `form`}
                      className="has-text-centered column is-6-desktop"
                    >
                      <div className="simple-border">
                        <a
                          href={form.document.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="has-text-primary"
                        >
                          <strong>{form.title}</strong>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <hr />
            {referenceranges && referenceranges.length ? (
              <div style={{ marginTop: `0` }}>
                <h4 className="has-text-centered">Reference Ranges</h4>
                <br />
                <div className="columns is-multiline is-desktop">
                  {referenceranges.map((referencerange) => (
                    <div
                      key={referencerange.title + `referencerange`}
                      className="has-text-centered column is-6-desktop"
                    >
                      <div className="simple-border">
                        <a
                          href={referencerange.document.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="has-text-primary"
                        >
                          <strong>{referencerange.title}</strong>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <hr />
            {/* <div style={{ marginTop: `0` }}>
              <h4 className="has-text-centered">
                Specimen Collection Guidelines
              </h4>
              <br />
              <div className="columns is-multiline is-desktop">
                <div className="has-text-centered column is-6-desktop">
                  <div className="simple-border">
                    <a
                      href={CollectionGuidelines}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="has-text-primary"
                    >
                      <strong>Specimen Collection Guidelines</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr /> */}
            <div style={{ marginTop: `0` }}>
              <a id="home-visits" href="anchor">
                <h4 className="has-text-centered">Home Visit Guidelines</h4>
              </a>
              <br />
              <div className="columns is-multiline is-desktop">
                <div className="has-text-centered column is-6-desktop">
                  <div className="simple-border">
                    <a
                      href={HomeVisits}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="has-text-primary"
                    >
                      <strong>Home Visit Guidelines</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProvidersPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  testingguides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      document: PropTypes.object,
    })
  ),
  antibioticprofiles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      document: PropTypes.object,
    })
  ),
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      document: PropTypes.object,
    })
  ),
  referenceranges: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      document: PropTypes.object,
    })
  ),
};

const Providers = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProvidersPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        testingguides={post.frontmatter.testingguides}
        antibioticprofiles={post.frontmatter.antibioticprofiles}
        forms={post.frontmatter.forms}
        referenceranges={post.frontmatter.referenceranges}
      />
    </Layout>
  );
};

Providers.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Providers;

export const pageQuery = graphql`
  query ProvidersByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        testingguides {
          title
          description
          document {
            publicURL
          }
        }
        antibioticprofiles {
          title
          description
          document {
            publicURL
          }
        }
        forms {
          title
          description
          document {
            publicURL
          }
        }
        referenceranges {
          title
          document {
            publicURL
          }
        }
      }
    }
  }
`;
